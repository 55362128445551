import styled from '@emotion/styled'

import { Button } from '@components/core/buttons'
import Spinner from '@components/core/spinner'

export const Header = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
})

export const Heading = styled.h1(
  {
    margin: '0 0 24px',
  },
  ({ theme }) => ({
    color: theme.colors.text.headline,
  }),
)

export const HeadingSecondaryWrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  margin: '48px 0 24px',
  width: '100%',

  '&:first-of-type': {
    marginTop: 0,
  },

  '@media (max-width: 950px)': {
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '10px',
  },
})

export const SettingsRowContentWrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  margin: '32px 0 16px',
  width: '100%',

  '&:first-of-type': {
    marginTop: 0,
  },

  '@media (max-width: 950px)': {
    flexDirection: 'row',
    gap: '10px',
  },

  '.ats-toggle': {
    marginRight: '10px',
  },
})

export const HeadingSecondary = styled.h2(
  {
    fontWeight: 600,
    margin: 0,
  },
  ({ theme }) => ({
    color: theme.colors.text.headline,
  }),
)

export const SwitchLabel = styled.label({
  display: 'inline-block',
  width: '40px',
  height: '18px',
  marginRight: '10px',
})

export const StyledLabel = styled.label(
  {
    cursor: 'pointer',
    fontSize: '0.9rem',
    marginRight: '10px',
  },
  ({ theme }) => ({
    color: theme.colors.text.axisLabel,
  }),
)

export const HeadingSecondaryActions = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
})

export const HeadingSecondaryActionButton = styled(Button)(
  {
    border: 'none',
    fontSize: '0.9rem',
    padding: '12px 18px',
  },
  ({ theme }) => ({
    background: theme.colors.backgrounds.primaryLight,
    borderRadius: theme.borderRadius.default,
    color: theme.colors.primary,

    '&:hover': {
      background: theme.colors.backgrounds.primary,
    },

    '&:focus': {
      outline: `2px solid ${theme.colors.primary}`,
    },
  }),
)

export const ExternalLinkIcon = styled.div({
  marginBottom: '-3px',
  marginLeft: '5px',
})

export const PlusImage = styled.div({
  marginLeft: '5px',
})

export const DeleteText = styled.span(
  {
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  ({ theme }) => ({
    color: theme.colors.text.red,
  }),
)

export const ViewDetailsText = styled.span(
  {
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  ({ theme }) => ({
    color: theme.colors.primary,
  }),
)

export const SettingsRowLoading = styled.div(
  {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0',
    minHeight: '78px',
    width: '100%',
  },
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.colors.border.default}`,
  }),
)

export const SettingsDescriptionRow = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  margin: '-15px 0 5px',
  width: '100%',
})

export const SettingsRow = styled.div(
  {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 0',
    width: '100%',

    '&:last-of-type': {
      marginBottom: '50px',
    },

    '@media (max-width: 950px)': {
      alignItems: 'flex-start',
      flexDirection: 'column',
      gap: '10px',
    },
  },
  ({ theme }) => ({
    borderBottom: `1px solid ${
      theme.isDark ? theme.colors.border.light : theme.colors.border.default
    }`,
  }),
)

export const SettingsRowError = styled.p({}, ({ theme }) => ({
  color: theme.colors.text.red,
}))

export const SettingsRowCentered = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: '20px 0',
  width: '100%',
})

export const SettingsRowColumn = styled.div(
  {
    display: 'flex',
    flexDirection: 'column',

    'p:first-of-type': {
      fontSize: '0.95rem',
      fontWeight: 600,
      margin: '0 0 3px',
    },
    p: {
      fontSize: '0.95rem',
      margin: '2px 0 0',

      a: {
        textDecoration: 'none',
      },

      '.method': {
        fontSize: '0.7rem',
        fontWeight: 'normal',
        lineHeight: 'normal',
        padding: '5px',
      },

      '.mb-0.5': {
        marginBottom: '3px',
      },
    },
  },
  ({ theme }) => ({
    'p:first-of-type': {
      color: theme.colors.text.headline,
    },
    p: {
      color: theme.isDark
        ? theme.colors.text.navigation
        : theme.colors.text.settingsLabel,

      '&.link': {
        color: theme.colors.primary,

        '&:hover': {
          textDecoration: 'underline',
        },
      },

      a: {
        color: theme.colors.primary,
      },

      '.method': {
        background: theme.colors.backgrounds.greyLight,
        borderRadius: theme.borderRadius.default,
        color: theme.colors.grey,
        marginRight: '5px',
      },
    },
    '@media (max-width: 950px)': {
      textAlign: 'left',
    },
  }),
)

export const SettingsRowColumnRight = styled(SettingsRowColumn)({
  alignItems: 'flex-end',
  textAlign: 'right',

  '@media (max-width: 950px)': {
    alignItems: 'flex-start',
    textAlign: 'left',

    'p.selected-environment': {
      marginTop: '13px',
    },
  },
})

export const SettingsRowLabel = styled.p(
  {
    margin: 0,
  },
  ({ theme }) => ({
    color: theme.colors.text.body,
  }),
)

export const SettingsRowValue = styled.p(
  {
    margin: 0,
    textAlign: 'right',
  },
  ({ theme }) => ({
    color: theme.colors.text.headline,
  }),
)

export const SettingsRowButton = styled(Button)(
  {
    border: 'none',
    fontSize: '0.9rem',
    padding: '12px 18px',
  },
  ({ theme }) => ({
    background: theme.colors.backgrounds.primaryLight,
    borderRadius: theme.borderRadius.default,
    color: theme.colors.primary,

    '&:hover': {
      background: theme.colors.backgrounds.primary,
    },

    '&:disabled': {
      background: theme.colors.backgrounds.greyLight,
      color: theme.colors.text.disabledButton,
    },

    '&:focus': {
      outline: `2px solid ${theme.colors.primary}`,
    },
  }),
)

export const SettingsRowDeleteButton = styled(SettingsRowButton)(
  {},
  ({ theme }) => ({
    background: theme.colors.backgrounds.red,
    color: theme.colors.text.buttonRed,

    '&:hover': {
      background: theme.colors.backgrounds.redDark,
    },

    '&:focus': {
      outline: `2px solid ${theme.colors.text.red}`,
    },
  }),
)

export const StatusWrapper = styled.p(
  {
    alignItems: 'flex-end',
    display: 'flex',
  },
  ({ theme }) => ({
    color: theme.isDark ? theme.colors.text.navigation : null,
  }),
)

export const GreenStatus = styled.span(
  {
    marginRight: '2px',
  },
  ({ theme }) => ({
    color: theme.colors.text.green,
  }),
)

export const RedStatus = styled.span(
  {
    marginRight: '2px',
  },
  ({ theme }) => ({
    color: theme.colors.text.red,
  }),
)

export const YellowStatus = styled.span(
  {
    marginRight: '2px',
  },
  ({ theme }) => ({
    color: theme.colors.text.yellow,
  }),
)

export const GreyStatus = styled.span(
  {
    marginRight: '2px',
  },
  ({ theme }) => ({
    color: theme.colors.text.body,
  }),
)

export const ShowMoreButton = styled.button(
  {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    padding: 0,
  },
  ({ theme }) => ({
    color: theme.colors.primary,
  }),
)
